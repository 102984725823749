






































import KButtonAction from '@/@core/components/button/KButtonAction.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KHeaderBackButton from '@/@core/components/page/KHeaderBackButton.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { IndustryCommonForm } from '../../types';
import { useIndustryStore as industryStore } from '../../services/store';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import router from '@/router';
import { useAuthStore } from '@/modules/auth/services/store';
import { useInitIndustryForm } from '../../helpers/initIndustryForm';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import OtherCostsForm from '../../ui/OtherCostsForm.vue';
import KBreadcrumbs from '@/@core/components/navigation/KBreadcrumbs.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KHeaderBackButton,
    KHamburgerMenu,
    KText,
    KPageBody,
    KWrapper,
    KTextInput,
    KButtonAction,
    OtherCostsForm,
    KBreadcrumbs
  },
  name: 'IndustryOperationalFormPage',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const reRenderCount = ref(0);
    const isEdit = computed(() => !!props.id);
    const isLoading = ref(false);
    const formData: Ref<IndustryCommonForm> = ref({
      name: '',
      address: '',
      district: '',
      village: '',
      tags: [],
      finance: undefined,
      geolocation: {
        latitude: undefined,
        longitude: undefined
      },
      npwp: '',
      businessEntity: '',
      ownerName: '',
      inChargeName: '',
      ownerAddress: '',
      phoneNumber: '',
      classification: undefined,
      employees: {
        male: undefined,
        female: undefined
      },
      commodities: []
    });

    const store = industryStore();

    const dummyData = () => {
      const newDummy = {
        name: 'Test A',
        address: 'Jl. ABC',
        geolocation: {
          latitude: -3.310882,
          longitude: 144.544871
        },
        businessEntity: 'Koperasi',
        npwp: '101010101010101',
        ownerName: 'An Owner',
        inChargeName: 'An in charge name',
        phoneNumber: '0895634775903',
        classification: '01112 - PERTANIAN GANDUM',
        socialMedias: [
          {
            type: 'instagram',
            value: 'testa'
          }
        ],
        employees: {
          male: 32,
          female: 10
        },
        commodities: ['Industri Pangan', 'Industri Sandang'],
        additionals: {
          suggestion: 'suggestion example'
        },
        // @ts-ignore
        surveyBy: useAuthStore().user?.id
      };

      formData.value = {
        ...formData.value,
        ...newDummy
      };

      reRenderCount.value++;
    };

    const newIndustry = async () => {
      const result = await store.postIndustry(formData.value);
      if (result)
        useUiStateStore().createSnackbar({
          message: `Industri ${formData.value.name} berhasil ditambahkan.`
        });

      return result;
    };

    const updateIndustry = async () => {
      const result = await store.putIndustry(formData.value);
      if (result)
        useUiStateStore().createSnackbar({
          message: `Industri ${formData.value.name} berhasil ditambahkan.`
        });

      return result;
    };

    const onSubmit = async () => {
      isLoading.value = true;

      let result;

      if (isEdit.value) result = await updateIndustry();
      else result = await newIndustry();

      const productId = result?.industry?.id;

      isLoading.value = false;

      if (productId) router.push(`/app/industri/${productId}`);
    };

    onMounted(async () => {
      const isEdit = props.id;

      if (isEdit) {
        const industry = await useInitIndustryForm(props.id);
        if (industry) {
          formData.value = {
            ...formData.value,
            ...industry
          };
        }

        reRenderCount.value++;
      }
    });

    return {
      formData,
      dummyData,
      isLoading,
      onSubmit,
      reRenderCount,
      isEdit,
      isMobile
    };
  }
});
